import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import ReactPlayer from 'react-player/lazy'
import {
  StyledAboutKarinaVideo,
} from "../../styledComponents/aboutKarina"
import Layout from "../../components/layout"
import HeroBanner from "../../components/HeroBanner"
import { HeroText } from "../../styledComponents/heroBanner"
import SignupFormLander  from "../../components/formSignupLander"

const LanderVideo = ({
    data, location
}) => {
  let landerType = 'emailCapture'
  
  return (
    <>
    <Helmet>
      <meta name={`robots`} content={`noindex, nofollow`} />x
    </Helmet>
    <Layout displayNavBorder isLander landerType location>
      <HeroBanner type={landerType}
        image="https://adminwp.karinagrant.co.uk/wp-content/uploads/2014/09/quantum-touch-earth-healing-1.jpg" 
      >
          <HeroText>
          <StyledAboutKarinaVideo>
            <ReactPlayer 
              width='100%'
              height='100%'
              className='react-player'
              // light='false'
              url='https://karinagrant.s3.eu-west-1.amazonaws.com/videos/karinagrant-video.mp4' 
              controls='true'
              config={{ file: { attributes: { controlsList: 'nodownload' } } }}
              onContextMenu={e => e.preventDefault()}
            />
          </StyledAboutKarinaVideo>
            <SignupFormLander />
          </HeroText>
      </HeroBanner>
    </Layout>
    </>
  )
}

export default LanderVideo

export const pageQuery = graphql`
  query {
    wpPage(slug: { eq: "homepage" }) {
      title
      slug
      content
      testimonials {
        blockquote {
          citeLocation
          citationName
          quote
        }
        testimonialHeading
        testimonialLede
      }
    }
    aboutKarina: wpPage(slug: { eq: "about" }) {
      title
      content
      date(formatString: "MMMM DD, YYYY")
      dateGmt
    }

    

    # OMNOM Event info
    post: wpEvent(id: {eq: "cG9zdDo2NjU4"}) { 
      id
      title
      content
      uri
      excerpt
      featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 975
                  quality: 100
                  placeholder: TRACED_SVG
                  breakpoints: [320, 720, 1024, 1600]
                )
              }
            }
          }
        }
      eventsMetaData {
        price
        priceSale
        stripefullprice
        stripesaleprice
        timezone
        location
        shortDescription
        datetime
      }
      eventsTag {
        nodes {
          slug
        }
      }
      testimonials {
        blockquote {
          citeLocation
          citationName
          quote
        }
        testimonialHeading
        testimonialLede
      }
    }

    event: allWpEvent(sort: {fields: eventsMetaData___datetime, order: ASC}, filter: {eventsMetaData: {location: {eq: "OMNOM Islington Square, London N1 1QP"}}}) {
      nodes {
        title
        id
        uri
        content
        eventsMetaData {
            price
            priceSale
            stripefullprice
            stripesaleprice
            timezone
            location
            shortDescription
            datetime
            eventpassed
        }
      }
    }
    fullPrice: allStripePrice(filter: {active: {eq: true}, id: {eq: "price_1JMWUdIfnzsCIRSOBPQ4IiRD"}}) {
      edges {
        node {
          id
          active
          currency
          unit_amount
          product {
            id
            name
            active
            images
          }
        }
      }
    }

    salePrice: allStripePrice(filter: {active: {eq: true}, id: {eq: "price_1JMVj4IfnzsCIRSOyNioMG5A"}}) {
      edges {
        node {
          id
          active
          currency
          unit_amount
          product {
            id
            name
            active
            images
          }
        }
      }
    }

    level1Data: allWpInformation(filter: {tags: {nodes: {elemMatch: {slug: {eq: "quantum-touch"}}}}, slug: {eq: "what-will-i-learn-on-a-quantum-touch-level-1-workshop"}}) {
      nodes {
        eventData {
          aboutQtLevel1
        }
      }
    }

    whatIsQtUsedFor: allWpInformation(filter: {id: {eq: "cG9zdDozMjY="}}) {
      nodes {
        title
        content
      }
    }

    whoShouldLearnQt: allWpInformation(filter: {slug: {eq: "who-should-learn-quantum-touch"}}) {
      nodes {
        title
        content
      }
    }

    whatIsQtEnergyHealing: allWpInformation(filter: {slug: {eq: "what-is-quantum-touch-energy-healing"}}) {
      nodes {
        title
        content
      }
    }


    hiChiMindfulnessShopPost: allWpProduct(filter: {slug: {eq: "the-essential-mindfulness-collection"}}) {
      nodes {
        title
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 975
                  quality: 100
                  placeholder: TRACED_SVG
                  breakpoints: [320, 720, 1024, 1600]
                )
              }
            }
          }
        }
      }
    }


    heroImage: allWpMediaItem(filter: {title: {eq: "hands-heart-world"}}) {
      nodes {
        title
        localFile {
          publicURL
        }
      }
    }    
  }
`

