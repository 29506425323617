import React, { useEffect, useState } from "react"
import { useFormik } from "formik"
import axios from "axios"
import { navigate } from "gatsby"

import { 
  StyledLanderSignupForm, 
  StyledLanderSignUpLede 
} from "../styledComponents/lander"

import {
  StyledForm,
  StyledFieldset,
  StyledInput,
  StyledLabel,
  StyledLabelHeader,
  StyledFormElementWrapper,
  ErrorMessage,
} from "../styledComponents/contact"
import { StyledButton } from "../styledComponents/button"
//import addToMailchimp from 'gatsby-plugin-mailchimp'


const SignupFormLander = () => {
  const [token, setToken] = useState("") // store token
  const [isSuccessMessage, setIsSuccessMessage] = useState(false) // manage is success message state
  const [messageSent, setMessageSent] = useState(false) // manage sent message state
  const [isFormValid, setIsFormValid] = useState(false)

  const interests = [
    // 07010ff6a3
    // curl -H "Authorization: apikey cedd0ed6f90a7c8205e334f8a9218411-us1" https://us1.api.mailchimp.com/3.0/lists/07010ff6a3/interest-categories/2e1178ad10/interests
    // 2e1178ad10 - Audio Purchase 
    {
        "id": "f52fdfb2ed",
        "name": "Balancing Structure"
    },
    {
      "id": "7aa6644a51",
      "name": "Quantum-Touch Morning Meditation"
    },
    {
      "id": "d6a7ff2064",
      "name": "Quantum-Touch Chakra Balance"
    },
    {
      "id": "65eaafac74",
      "name": "Quantum-Touch Brain Healing"
    },
    {
      "id":"d950a0b77f",
      "name":"The Essential Mindfulness Collection"
    },
    {
      "id":"637a687588",
      "name":"Chakra Balancing Collection"
    },
    // Interest ae936beae9
    {
      "id": "cb4f15c6f6",
      "name": "Newsletter Sign Up"
    },
    {
      "id": "a7b7044e05",
      "name": "Contact form"
    },
    // 63a3c2f2bc Events 
    {
      "id": "076cec495e",
      "name": "Quantum-Touch Free Healing Circle"
    }
  ]

  // Validation
  const validate = values => {
    const errors = {}
    if (!values.firstName) {
      errors.firstName = "Required"
    }
    if (!values.lastName) {
      errors.lastName = "Required"
    }

    if (!values.email) {
      errors.email = "Required"
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address"
    }

    Object.keys(errors).length ? setIsFormValid(false) : setIsFormValid(true)
    return errors
  }

  // this effect function authenticates our subcriber user to get a token
  useEffect(() => {
    axios({
      method: "post",
      url: `https://adminwp.karinagrant.co.uk/wp-json/jwt-auth/v1/token`,
      data: {
        username: `contactform`, // provide a user credential with subscriber role
        password: `qR9zizJq^qKbv2#5nopD8ly#`,
      },
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(response => {
        setToken(response.data.token)
      })
      .catch(error => console.error("Error", error))
  }, [])

  // use useFormik hook using object destructuring assignment to extract helpful methods
  const {
    handleChange,
    handleBlur,
    isSubmitting,
    values,
    handleSubmit,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
    },
    validate,
    onSubmit: (
      {
        firstName,
        lastName,
        email,
      },
      { setSubmitting, resetForm }
    ) => {
      setSubmitting(true)
      
      const bodyFormData = new FormData()
      bodyFormData.set("firstName", firstName)
      bodyFormData.set("lastName", lastName)
      bodyFormData.set("email", email)
      // here we sent
      axios({
        method: "post",
        url: `https://adminwp.karinagrant.co.uk/wp-json/contact-form-7/v1/contact-forms/5457/feedback`,
        data: bodyFormData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
        .then(response => {
          // actions taken when submission goes OK
          resetForm()
          setSubmitting(false)

          // addToMailchimp(values.email, {
          //   FIRSTNAME: fullName,
          //   'group[380914][1]': '1'
          // })
          // let fName, lName
          // if(values.fullName.trim().indexOf(' ') != -1){
          //   fName = fullName.split(' ').slice(0, -1).join(' ')
          //   lName = fullName.split(' ').slice(-1).join(' ')
          // } else {
          //   fName = fullName
          //   lName = ''
          // }
          axios.post("/.netlify/functions/formHandler" , {firstName:firstName , lastName:lastName ,emailAddress:email.toLowerCase() , interest: interests[6]})
          .then(data => {
            console.log('ERROR ',data)
          })
          .catch(console.log)

          setMessageSent(true)
          setIsSuccessMessage(true)
          navigate("/lander/omnom-quantum-touch-level-1")
        })
        .catch(error => {
          // actions taken when submission goes wrong
          setSubmitting(false)
          setMessageSent(true)
          setIsSuccessMessage(false)
        })
    },
  })

  useEffect(() => {
    // set timeout 3 seconds to remove error/success message.
    setTimeout(() => {
      // this will reset messageSent and isSuccessMessage state
      setMessageSent(false)
      setIsSuccessMessage(false)
    }, 3000)
    // this effect function will be dispatched when isSuccessMessage or messageSent changes its state
  }, [isSuccessMessage, messageSent])

  return (
    <>
    <StyledLanderSignupForm>
      <StyledLanderSignUpLede>Signup for Karina's newsletter to receive your FREE Quantum-Touch eBook &amp; audio session with Richard Gordon the founder</StyledLanderSignUpLede> 
      <StyledForm onSubmit={handleSubmit}>
        <StyledFieldset>
        <StyledFormElementWrapper>
          <StyledLabel htmlFor="firstName">
            <StyledLabelHeader
              required
              isEmpty={errors.firstName && touched.firstName}
              hidden={true}
              // className="hidden"
            >
              First name
            </StyledLabelHeader>
            <StyledInput
              id="firstName"
              name="firstName"
              type="text"
              placeholder="First name"
              onChange={handleChange}
              value={values.firstName}
              onBlur={handleBlur}
              required
            />
          </StyledLabel>
          {errors.firstName && touched.firstName ? (
            <span className="error-message">{errors.firstName}</span>
          ) : null}
        </StyledFormElementWrapper>

        <StyledFormElementWrapper>
          <StyledLabel htmlFor="lastName">
            <StyledLabelHeader
              required
              isEmpty={errors.lastName && touched.lastName}
              hidden={true}
              // className="hidden"
            >
              Last name
            </StyledLabelHeader>
            <StyledInput
              id="lastName"
              name="lastName"
              type="text"
              placeholder="Last name"
              onChange={handleChange}
              value={values.lastName}
              onBlur={handleBlur}
              required
            />
          </StyledLabel>
          {errors.lastName && touched.lastName ? (
            <span className="error-message">{errors.lastName}</span>
          ) : null}
        </StyledFormElementWrapper>
        <StyledFormElementWrapper>
          <StyledLabel htmlFor="email">
            <StyledLabelHeader
              required
              hidden={true}
              isEmpty={errors.email && touched.email}
            >
              Email Address
            </StyledLabelHeader>
            <StyledInput
              id="email"
              name="email"
              type="email"
              onChange={handleChange}
              value={values.email}
              placeholder="Email Address"
              onBlur={handleBlur("email")}
              required
            />
          </StyledLabel>
          {errors.email && touched.email ? (
            <span className="error-message">{errors.email}</span>
          ) : null}
        </StyledFormElementWrapper>
        
        <StyledButton
        type="submit"
        value="Send Message"
        disabled={isSubmitting || !isFormValid}
      
      >
        Subscribe
        </StyledButton>
          
      </StyledFieldset>
      
        {messageSent && !isSuccessMessage && (
          <ErrorMessage>
            <p>Something went wrong please try again.</p>
          </ErrorMessage>
        )}
      </StyledForm>
    </StyledLanderSignupForm>
  </>
  )
}

export default SignupFormLander
